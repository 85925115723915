import Swiper from 'swiper/bundle';

window.addEventListener('load', function () {
    var mySwiperIndex = new Swiper(".mySwiperIndex", {
        spaceBetween: 30,
        effect: "fade",
        loop: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });
    var mySwiperIndex2 = new Swiper(".mySwiperIndex2", {
        spaceBetween: 30,
        effect: "fade",
        loop: true,
        autoplay: {
            delay: 4500,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
    var mySwiper = new Swiper(".mySwiper", {
        slidesPerView: 1,
        spaceBetween: 10,
        speed: 3000,
        loop: true,
        breakpoints: {
            240: {
                slidesPerView: 1,
                spaceBetween: 10,
                speed: 800,
            },
            450: {
                slidesPerView: 2,
                spaceBetween: 10,
                speed: 800,
            },
            790: {
                slidesPerView: 4,
                spaceBetween: 10,
                speed: 800,
            },
            1100: {
                slidesPerView: 5,
                spaceBetween: 10,
            },
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    var swiper = new Swiper(".mySwiperCorporate", {
        slidesPerView: 1,
        spaceBetween: 30,
        speed: 3000,
        loop: true,
        breakpoints: {
            240: {
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 800,
            },
            450: {
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 800,
            },
            790: {
            slidesPerView: 2,
            spaceBetween: 30,
            speed: 800,
            },
            1100: {
            slidesPerView: 3,
            spaceBetween: 30,
            },
            1200: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            1700: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });


    var mySwiper2 = new Swiper(".mySwiper2", {
        direction: "vertical",
        slidesPerView: 3,
        spaceBetween: 0,
        speed: 1000,
        breakpoints: {
            240: {
                slidesPerView: 1,
                spaceBetween: 20,
                speed: 800,
            },
            991: {
                slidesPerView: 3,
                spaceBetween: 0,
            },
        },
        navigation: {
            nextEl: ".swiper-button-next2",
            prevEl: ".swiper-button-prev2",
        },
    });

    var swiper2 = new Swiper(".mySwiper3", {
        slidesPerView: 4,
        spaceBetween: 10,
        speed: 3000,
        loop: true,
        breakpoints: {
            240: {
                slidesPerView: 1,
                spaceBetween: 10,
                speed: 800,
            },
            450: {
                slidesPerView: 2,
                spaceBetween: 10,
                speed: 800,
            },
            790: {
                slidesPerView: 3,
                spaceBetween: 10,
                speed: 800,
            },
            1100: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });


    var swiper = new Swiper(".mySwiperVideos1", {
            slidesPerView: 3,
            spaceBetween: 20,
            speed: 3000,
            loop: true,
            breakpoints: {
                240: {
                slidesPerView: 1,
                spaceBetween: 20,
                speed: 800,
                },
                450: {
                slidesPerView: 2,
                spaceBetween: 20,
                speed: 800,
                },
                790: {
                slidesPerView: 3,
                spaceBetween: 20,
                speed: 800,
                },
                1100: {
                slidesPerView: 3,
                spaceBetween: 20,
                },
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

    var swiper = new Swiper(".mySwiperVideos2", {
        slidesPerView: 4,
        spaceBetween: 10,
        speed: 3000,
        loop: true,
        breakpoints: {
            240: {
            slidesPerView: 1,
            spaceBetween: 10,
            speed: 800,
            },
            450: {
            slidesPerView: 2,
            spaceBetween: 10,
            speed: 800,
            },
            790: {
            slidesPerView: 3,
            spaceBetween: 10,
            speed: 800,
            },
            1100: {
            slidesPerView: 4,
            spaceBetween: 10,
            },
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

});
